<template lang="html">
  <footer class="footer">
    <div class="container grid-links">
      <div class="grid">
        <router-link :to="{ name: 'site-map' }">Mapa web</router-link>
        <a
          href="https://www.ufv.es/aviso-legal/"
          target="_blank"
          title="aviso legal"
          >Aviso Legal</a
        >
        <a
          href="/politica-de-privacidad"
          title="Política de privacidad"
          target="_blank"
          >Política de Privacidad</a
        >
        <a
          href="/politica-de-cookies"
          title="Política de cookies"
          target="_blank"
          >Política de Cookies</a
        >
      </div>
      <div class="grid">
        <p class="rrss-title">
          <a
            href="https://www.facebook.com/UFVmadrid/"
            target="_blank"
            title="facebook"
          >
            <img src="/img/icons/facebook-white.svg" alt="Facebook" />
          </a>
          <a
            href="https://x.com/ufvmadrid"
            target="_blank"
            title="x"
          >
            <img src="/img/icons/twitter-white.svg" alt="Twitter" />
          </a>
          <a
            href="https://www.instagram.com/ufvmadrid/"
            target="_blank"
            title="instagram"
          >
            <img src="/img/icons/instagram-white.svg" alt="Instagram" />
          </a>
          <a
            href="https://www.linkedin.com/school/universidad-francisco-de-vitoria-/"
            target="_blank"
            title="linkedin"
          >
            <img src="/img/icons/linkedin-white.svg" alt="Linkedin" />
          </a>
          <a
            href="https://www.youtube.com/user/ufvmadrid"
            target="_blank"
            title="youtube"
          >
            <img src="/img/icons/youtube-white.svg" alt="Youtube" />
          </a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script lang="js">
import { contentStore } from '@/stores/contents'
export default {
  name: 'footer',
  props: [],
  setup() {
    const store = contentStore();

    return {
      store
    }
  },
}
</script>

<style scoped lang="scss">
@import "../styles/general.scss";

.footer {
  padding: 10px 0;
  background: #12182e;
  .grid-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .grid {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .title {
        font-weight: bold;
        font-size: 16px;
        color: $blueDark;
        margin-bottom: 10px;
      }

      a {
        text-decoration: none;
        color: $blue;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        color: white;
        margin-left: 40px;

        img {
          max-width: 25px;
          margin-left: 10px;
        }
      }
      &:last-of-type {
        justify-content: flex-end;
        width: 30%;
        a {
          margin-left: 0;
        }
      }

      .rrss-title {
        text-decoration: none;
        color: $blue;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        a {
          margin-right: 8px;
          margin-top: 5px;
          display: flex;
          background: white;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;

          img {
            height: 16px;
            margin-left: 0px;
          }

          i {
            color: #12182e;
            background: $blue;
            font-size: 17px;
            width: 20px;
            height: 20px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .footer .grid-links .grid,
    .footer .grid-links .grid:last-of-type{
    width: 100%;
    margin-right:0;
    margin-right: 0;
    padding-top: 0 !important;
    justify-content: center;
    a{
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 650px) {
  .footer .grid-links .grid a {
    text-align: center;
  }

  .footer .grid-links .grid {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
</style>
