import { defineStore } from "pinia";
import axios from "axios";
import utils from "@/utils/utils.js";
function getEnty(object, slug) {
  var entry = null;
  Object.keys(object).forEach((key) => {
    if (object[key][slug]) {
      entry = object[key][slug];
    }
  });
  return entry;
}
function checkForNewPage(object, page, filter, text) {
  if (object.lastFilters.filter == filter && object.lastFilters.text == text) {
    if (
      object.items[page] != null &&
      Object.keys(object.items[page]).length > 0
    ) {
      return null;
    }
  } else {
    return -1;
  }
  return page;
}
const ENTRIES_PAGE = 4;
export const contentStore = defineStore("content", {
  state: () => ({
    loading: false,
    departments: {},
    allDepartments: {},
    gestions: {},
    gestionsDirectAccess: {
      items: {},
    },
    offers: {},
    entries: {
      viewPage: 1,
      lastFilters: {
        filter: null,
        text: null,
      },
      items: {},
      maxItems: null,
    },
    adds: {
      items: {},
    },
    employees: {},
    organigrama: {
      0: [],
      1: {},
      2: {},
    },
    lightEntries: {},
    specialEntries: {},
    categoriesEntries: {},
  }),
  getters: {
    getDepartamentBySlug: function (state) {
      return (slug) => {
        return state.departments[slug];
      };
    },
    getGestionsByDepartment: function (state) {
      return (slug) => {
        return state.gestions[slug].items;
      };
    },
    getCategoriesByDepartment: function (state) {
      return (department) => {
        if (state.gestions[department] == null) {
          return {};
        }
        let categories = {};

        //Recorremos por un departamento y sacamos gestiones
        Object.values(state.gestions[department].items).forEach((gestion) => {
          if (gestion.categories.length <= 0) {
            categories[gestion.slug] = gestion;
            if (gestion.dotsEnabled.files || gestion.dotsEnabled.applications) {
              categories[gestion.slug].dotEnabled = true;
            }
          } else {
            //Recorremos las categorias de cada
            gestion.categories.forEach((category) => {
              //Aqui iniciamos la categoria si no esta ya creada
              if (categories[category.slug] == null) {
                categories[category.slug] = category;
                categories[category.slug].items = [];
                categories[category.slug].dotEnabled = false;
              }

              //Vemos si la categoria puede tener el circulo de nueva gestion
              if (
                gestion.dotsEnabled.files ||
                gestion.dotsEnabled.applications
              ) {
                categories[category.slug].dotEnabled = true;
              }

              //Añadimos lo minimo para poder pintar la gestión en el menu
              categories[category.slug].items.push({
                name: gestion.name,
                slug: gestion.slug,
                dotsEnabled: gestion.dotsEnabled,
              });
            });
          }
        });

        console.log(categories);
        return categories;
      };
    },
    getEmployerById: function (state) {
      return (id) => {
        var e = "";
        Object.values(state.employees).forEach((element) => {
          if (element.id == id) {
            return (e = element);
          }
        });
        return e;
      };
    },
    getLightEntryByCategoryBySLug: (state) => {
      return (slug, avoidSlug = null) => {
        return Object.values(state.lightEntries).filter((element) => {
          return element.category.slug == slug && avoidSlug != element.slug;
        });
      };
    },
    getEntryByCategoryBySLug: (state) => {
      return (slug, avoidSlug = null) => {
        return Object.values(state.entries.items).filter((element) => {
          return element.category.slug == slug && avoidSlug != element.slug;
        });
      };
    },
    getLightEntryBySLug: (state) => {
      return (slug) => {
        return state.lightEntries[slug];
      };
    },
    setPageEntries: function (state) {
      return (page) => {
        return (state.entries.viewPage = page);
      };
    },
    getEntries: (state) => (page) => {
      return state.entries.items[page];
    },
    getEntriesPage: (state) => {
      return state.entries.viewPage;
    },
    getPagesEntries:
      (state) =>
      (itemsPerPage = ENTRIES_PAGE) => {
        var pages = Math.ceil(state.entries.maxItems / itemsPerPage);
        return pages;
      },
  },
  actions: {
    async loadDepartments() {
      var d = this.departments;
      if (Object.keys(d).length > 0) {
        return;
      }
      let data = {
        raw: true,
        columns: [
          {
            name: "arrangements",
            withCount: "true",
            key: "arrangements_count",
          },
        ],

        filters: [
          {
            filterPack: true,
            filters: [
              {
                name: "position",
                search: [1, 2],
              },
            ],
          },
          {
            filterPack: true,
            filters: [
              {
                name: "arrangements",
                has: true,
                search: {
                  min: 1,
                },
              },
            ],
          },
        ],

        order: [
          {
            name: "order",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("departments", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            d[element.slug] = element;
          });

          resolve();
        });
      });
    },
    async loadAllDepartments() {
      var ad = this.allDepartments;
      if (Object.keys(ad).length > 0) {
        return;
      }
      let data = {
        raw: true,
        columns: [
          {
            name: "arrangements",
            withCount: "true",
            key: "arrangements_count",
          },
        ],

        filters: [
          {
            filterPack: true,
            filters: [
              {
                name: "position",
                search: [0, 1, 2],
              },
            ],
          },
          {
            filterPack: true,
            filters: [
              {
                name: "arrangements",
                has: true,
                search: {
                  min: 1,
                },
              },
            ],
          },
        ],

        order: [
          {
            name: "order",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("departments", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            ad[element.slug] = element;
          });

          resolve();
        });
      });
    },
    async loadGestions({
      page = 1,
      length = 20,
      slug = "default",
      textSearch = null,
    } = {}) {
      let filtersSelect = [
        {
          name: "departments.slug",
          search: slug,
        },
      ];
      let filters = utils.generateTextFilters(textSearch, ["name", "header"]);
      let data = {
        page: page,
        length: length,
        columns: [
          {
            name: "departments",
            onlyWith: true,
          },
          {
            name: "applications",
            onlyWith: true,
          },
          {
            name: " files",
            onlyWith: true,
          },
          {
            name: "categories",
            onlyWith: true,
          },
          {
            name: "applications.files",
            onlyWith: true,
          },
        ],
        filters: filtersSelect.concat(filters),
        order: [
          {
            name: "order",
            direction: "asc",
          },
        ],
      };

      if (
        this.gestions[slug] !== undefined &&
        Object.keys(this.gestions[slug].items).length > 0 &&
        this.gestions[slug].textSearch == textSearch
      ) {
        return;
      }

      if (
        this.gestions[slug] !== undefined &&
        this.gestions[slug].textSearch != textSearch
      ) {
        this.gestions[slug] = null;
      }

      let t = this;
      return new Promise((resolve) => {
        var promise = axios.post("arrangement", data);
        promise.then(function (result) {
          t.gestions[slug] = {
            items: {},
            page: null,
            textSearch: null,
          };
          result.data.data.forEach((gestion) => {
            gestion = t.updateGestion(gestion);
            t.gestions[slug].items[gestion.slug] = gestion;
          });
          t.gestions[slug].page = page;
          t.gestions[slug].textSearch = textSearch;
          resolve();
        });
      });
    },
    updateGestion(gestion) {
      let files = {};

      gestion.dotsEnabled = {
        files: false,
        applications: false,

        filesType: [],
        applicationsType: [],
      };

      gestion.applications.forEach(function (a) {
        gestion.files = gestion.files.concat(a.files);
        if (a.new || a.updated) {
          gestion.dotsEnabled.applications = true;
          gestion.dotsEnabled.applicationsType.push(a.type);
        }
      });

      gestion.files.forEach(function (f) {
        if (files[f.type] == null) {
          files[f.type] = [f];
        } else {
          files[f.type].push(f);
        }
        if (f.new || f.updated) {
          gestion.dotsEnabled.files = true;
          gestion.dotsEnabled.filesType.push(f.type);
        }
      });
      gestion.files = files;

      gestion.dotsEnabled.filesType = [
        ...new Set(gestion.dotsEnabled.filesType),
      ];
      gestion.dotsEnabled.applicationsType = [
        ...new Set(gestion.dotsEnabled.applicationsType),
      ];

      return gestion;
    },
    async loadDirectAccess() {
      let filtersSelect = [
        {
          name: "visible_fast",
          search: 1,
        },
      ];
      let data = {
        columns: [
          {
            name: "departments",
            onlyWith: true,
          },
        ],
        filters: filtersSelect,
        order: [
          {
            name: "name",
            direction: "asc",
          },
        ],
      };
      let t = this;
      if (
        this.gestionsDirectAccess &&
        Object.keys(this.gestionsDirectAccess.items).length > 0
      ) {
        return;
      }
      return new Promise((resolve) => {
        var promise = axios.post("arrangement", data);
        promise.then(function (result) {
          t.gestionsDirectAccess = {
            items: {},
          };
          result.data.data.forEach((gestion) => {
            t.gestionsDirectAccess.items[gestion.slug] = gestion;
          });
          resolve();
        });
      });
    },
    async loadGestionbySlug({ slug = "default" } = {}) {
      let t = this;
      return new Promise((resolve) => {
        axios
          .get(
            "arrangement/" +
              slug +
              "?c-load[]=categories&c-load[]=departments&c-load[]=applications&c-load[]=files"
          )
          .then(function (result) {
            let multipleStatus = t.getGestionStatus(result.data);
            result.data.filesTypes = multipleStatus.filesTypes;
            result.data.filesStatus = multipleStatus.filesStatus;
            result.data.statusNew = multipleStatus.gestionStatus;
            resolve(result.data);
          });
      });
    },
    async loadAdds() {
      let filtersSelect = [
        {
          name: "visible",
          search: 1,
        },
      ];
      let data = {
        filters: filtersSelect,
      };
      let t = this;
      if (this.adds && Object.keys(this.adds.items).length > 0) {
        return;
      }
      return new Promise((resolve) => {
        var promise = axios.post("ads", data);
        promise.then(function (result) {
          t.adds.items = result.data.data;
          resolve();
        });
      });
    },
    async loadEmployees() {
      let data = {
        order: [
          {
            name: "order",
            direction: "asc",
          },
        ],
        columns: [
          {
            name: "department",
            onlyWith: true,
          },
        ],
      };
      let t = this;
      if (this.employees && Object.keys(this.employees).length > 0) {
        return;
      }
      return new Promise((resolve) => {
        var promise = axios.post("employees", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            // console.log(element)
            t.employees[element.surnames] = element;
            if (element.level == 0) {
              t.organigrama[element.level].push(element);
            } else {
              if (
                t.organigrama[element.level][element.department.slug] == null
              ) {
                t.organigrama[element.level][element.department.slug] = [];
              }
              t.organigrama[element.level][element.department.slug].push(
                element
              );
            }
          });

          resolve();
        });
      });
    },
    async loadLightEntries() {
      let filtersSelect = [
        {
          name: "visible",
          search: 1,
        },
      ];
      let data = {
        columns: [
          {
            name: "category",
            onlyWith: true,
          },
        ],
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
        filters: filtersSelect,
      };
      let t = this;
      if (this.lightEntries && Object.keys(this.lightEntries).length > 0) {
        return;
      }
      return new Promise((resolve) => {
        var promise = axios.post("light-entries", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            t.lightEntries[element.slug] = element;
          });
          resolve();
        });
      });
    },
    async loadCategoriesEntries() {
      let t = this;
      if (
        this.categoriesEntries &&
        Object.keys(this.categoriesEntries).length > 0
      ) {
        return;
      }
      return new Promise((resolve) => {
        var promise = axios.post("entries/category");
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            t.categoriesEntries[element.slug] = element;
          });
          resolve();
        });
      });
    },
    async loadEntries({
      page = 1,
      length = ENTRIES_PAGE,
      filter = this.entries.lastFilters.filter,
      text = this.entries.lastFilters.text,
    } = {}) {
      page = checkForNewPage(this.entries, page, filter, text);
      if (page == -1) {
        page = 1;
        this.entries.items = {};
      } else if (page === null) {
        return;
      }
      let filtersSelect = [
        {
          name: "visible",
          search: 1,
        },
        {
          name: "category.id",
          search: filter,
        },
      ];
      let filters = utils.generateTextFilters(text, ["name", "header"]);
      let data = {
        page: page,
        length: length,
        columns: [
          {
            name: "category",
            onlyWith: true,
          },
        ],
        filters: filtersSelect.concat(filters),
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };
      let t = this;

      // if (text !== null && text.length == 0) {
      //     text = null
      // }
      // if (this.entries.lastFilters.text != text || this.entries.lastFilters.filter != filter || this.entries.viewPage != page) {
      //     this.entries.items = {}
      // }
      // if (this.entries.items && Object.keys(this.entries.items).length > 0) {
      //     return;
      // }

      return new Promise((resolve) => {
        var promise = axios.post("entries", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            if (t.entries.items[page] == null) {
              t.entries.items[page] = {};
            }
            t.entries.items[page][element.slug] = element;
            // t.entries.items[element.slug] = element,
            (t.entries.viewPage = page),
              (t.entries.maxItems = result.data.recordsFiltered),
              (t.entries.lastFilters = {
                filter: filter,
                text: text,
              });
          });
          resolve();
        });
      });
    },
    async loadEntry({ slug = "default" } = {}) {
      var entry = getEnty(this.entries.items, slug);
      if (entry) {
        return entry;
      }
      return new Promise((resolve) => {
        axios
          .get("entries/" + slug + "?c-load[]=category")
          .then(function (result) {
            resolve(result.data);
          });
      });
    },
    async loadSpecialEntries() {
      let filtersSelect = [
        {
          name: "visible",
          search: 1,
        },
        {
          name: "featured",
          search: 1,
        },
      ];
      let data = {
        columns: [
          {
            name: "category",
            onlyWith: true,
          },
        ],
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
        filters: filtersSelect,
      };
      let t = this;
      if (this.specialEntries && Object.keys(this.specialEntries).length > 0) {
        return;
      }
      return new Promise((resolve) => {
        var promise = axios.post("entries", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            t.specialEntries[element.slug] = element;
          });
          resolve();
        });
      });
    },

    async loadOffers({ textSearch = null } = {}) {
      console.log(textSearch);
      let filtersSelect = [
        {
          name: "visible",
          search: 1,
        },
        {
          name: "name",
          search: textSearch,
          textFilter: 1,
        },
      ];
      let data = {
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
        filters: filtersSelect,
      };
      let t = this;
      if (textSearch != null) {
        t.offers = {};
      } else {
        if (this.offers && Object.keys(this.offers).length > 0) {
          return;
        }
      }

      return new Promise((resolve) => {
        var promise = axios.post("offers", data);
        promise.then(function (result) {
          // t.offers = result.data.data
          result.data.data.forEach((element) => {
            t.offers[element.slug] = element;
          });
          resolve();
        });
      });
    },
  },
});
